.footer {
    background-color: rgba(0, 0, 0, 0.6);
    position: relative;
    left: 0;
    bottom: 0;
    right: 0;
}
.footer-fixed {
    position: fixed !important;
}

.nav-submenu > .Mui-selected {
    background: none !important;
    border-left: 5px rgb(41, 41, 41) solid !important;
}
.container-principal {
    width: 100% !important;
    background-color: lightgray;
    padding: 0;
}
.div-relleno {
    height: 1.5em !important;
}
.mas-informacion {
    position: absolute !important;
    right: 2%;
}

.modificar-btn {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}
.color-letras {
    color: lightgray !important;
}
.color-letras-dark {
    color: black !important;
}
.select-formulario {
    width: 100%;
}
.menuLogin {
    margin-top: 1em;
    padding-top: 3em;
    padding-bottom: 4em;
    border-radius: 10px;
}
.menuLoginDark {
    background-color: rgb(185, 185, 185);
}
.menuLoginLight {
    background-color: rgba(179, 179, 179, 0.808);
}

.h85 {
    height: 85% !important;
}
.bg-box-dark {
    background-color: rgba(20, 20, 20, 0.9) !important; /* Color ah*/
}

.input-upload {
    background-image: url("../assets/generals/icono_upload.png");
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
}
.masInfoDialogDark {
    background-color: rgba(0, 0, 0, 1); /* Color ah*/
    color: lightgray;
}
.masInfoDialogLight {
    background-color: rgba(255, 255, 255, 1);
    color: black !important;
}
.input-olvidado {
    background-color: rgba(238, 238, 238, 0.52) !important; /* Color ah*/
    border-radius: 5px;
}
