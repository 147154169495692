.texto-wrap {
    overflow: hidden;
    position: relative;
}

.texto-bg {
    opacity: 0.6;
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;

    /* Add the blur effect */
    -webkit-filter: blur(3px);
    filter: blur(3px);
}

.texto-20 {
    font-size: 20px;
}
.texto-18 {
    font-size: 18px;
}
.texto-content {
    height: 100%;

    position: relative;
    color: lightgray;
}

.texto-content-dark:hover,
.texto-content-dark::after {
    background-color: rgba(0, 0, 0, 0.4);
}
.texto-content-light:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.texto-content > div > div > p {
    margin-bottom: 0px;
}

.bg-textos {
    background-image: url(""); /* The image used */
    height: auto; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */

    background-blend-mode: darken;
}
