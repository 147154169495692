.markdown-elements {
    min-height: 400px;
    width: auto !important;
    max-width: 100%;
    opacity: 0;
    height: 100%;
    border: none;
}
.markdown-image {
    width: 100%;
    height: 100%;
    border: none;
}

.inputFileMarkdown {
    position: absolute;
    height: 100%;
    width: 96.5%;

    z-index: 1;
    display: none;
    border: 5px solid rgba(0, 0, 219, 0.822);
}
.fileInputMarkdown {
    height: 100% !important;
    width: 100%;
    opacity: 0 !important;
}
.fileInputMarkdown > .custom-file-input {
    height: 100% !important;
    width: 100%;
}
.markdown-cookies {
    background-repeat: no-repeat;
    background-position: top right;
    background-image: url("../assets/generals/cookies.png");
}
/* .markdown-no-content {
    background-repeat: no-repeat;

    background-position: right;
    background-image: url("../assets/generals/grua.png");
} */
.contendor-editar {
    width: auto !important;
    padding: 2em !important;
    max-width: 100%;
    height: 80vh;
}
.limitar-ancho-editor {
    max-width: calc(100vw - 240px);
}
.mostrar-editor {
    width: auto;
    word-wrap: break-word;
    word-break: break-word;
    overflow-y: scroll !important;
    max-height: 70vh;
}
.parrafo-md {
    word-wrap: break-word;
    text-align: justify;
    word-break: break-word;
    max-width: 100%;
}
