.imagen-portada {
    max-width: 100% !important;

    position: absolute;
    background-size: cover;
    height: calc(100vh);
    background-position: center;

    overflow: hidden;
    opacity: 1;
}
.imagen-portada-vert {
    background-position: center top !important;
}
.imagen-portada-fade {
    opacity: 0;
    transition: opacity 2s ease-in-out;
}

.active {
    opacity: 1;
    transition: opacity 2s ease-in-out;
}
.portadaStatemenet {
    padding-top: 50px;
    font-size: 1.3em;
    color: white;
    background-color: rgba(0, 0, 0, 0.85);
    padding-right: 2% !important;
}
.portadaTablet {
    font-size: 1.1em !important;
}

.portadaMovil {
    padding-top: 30px;
    font-size: 0.7em !important;
}
