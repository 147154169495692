@font-face {
    font-family: "Century Gothic Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Century Gothic Regular"), url("GOTHIC.woff") format("woff");
}

@font-face {
    font-family: "Century Gothic Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Century Gothic Italic"), url("GOTHICI.woff") format("woff");
}

@font-face {
    font-family: "Century Gothic Bold";
    font-style: normal;
    font-weight: normal;
    src: local("Century Gothic Bold"), url("GOTHICB.woff") format("woff");
}

@font-face {
    font-family: "Century Gothic Bold Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Century Gothic Bold Italic"), url("GOTHICBI.woff") format("woff");
}
.MuiTypography-body1,
.MuiTypography-root,
.MuiGridListTileBar-title,
.MuiInputBase-input,
.MuiInputLabel-root,
.MuiTooltip-tooltip,
.btn,
.letra-familia {
    font-family: "Century Gothic Regular" !important;
}
/* .MuiInputLabel-root {
    font-size: inherit !important;
} */
