.bg-formulario-dark {
    background-color: rgba(2, 2, 2, 0);

    color: black;
}
.bg-formulario-light {
    background-color: rgba(250, 250, 250, 0);

    color: black;
}

.formulario-input-dark > .MuiInputBase-root > .MuiInputBase-input {
    color: lightgray;
}

.formulario-input-dark > .MuiFormControl-root > .MuiInputBase-root > .MuiInputBase-input {
    color: lightgray;
}

.formulario-input-dark > .MuiFormControl-root > .MuiInputBase-root > .MuiInputBase-input {
    color: lightgray;
}

.formulario-input-dark > .MuiFormLabel-root {
    color: lightgray !important;
}

.formulario-input-dark > .MuiFormControl-root > .MuiInputLabel-root {
    color: lightgray !important;
}
.formulario-input-dark > .MuiInputBase-root > .MuiOutlinedInput-notchedOutline {
    border-color: lightgray !important;
}
.formulario-input-dark > .MuiInput-underline::before {
    border-color: lightgray !important;
}

.formulario-input-dark > .MuiInput-underline::after {
    border-color: white !important;
}

.formulario-input-dark > .MuiTextField-root > .MuiInput-underline::before {
    border-color: lightgray !important;
}

.formulario-input-dark > .MuiTextField-root > .MuiInput-underline::after {
    border-color: white !important;
}
