.MuiListItemText-root {
    margin: 0 !important;
}
.col {
    padding-right: 0 !important;
    padding-left: 2px !important;
}
.Mui-selected {
    border-left: none;
    border-right: 5px rgb(58, 58, 58) solid !important;
}
