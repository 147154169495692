.bg-black {
    background-color: black;
}

.control-slide {
    max-width: 50px;
    position: absolute;
    z-index: 1;
    margin: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.control-slide-right {
    right: 0;
}
.control-slide:active,
.control-slide:hover {
    border-radius: 100%;
    background-color: none !important;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.1);
}

.icons-arrows {
    margin: auto;

    height: 1.25em !important;
    text-align: center !important;
    width: 1.25em !important;
}
.icono-wrap {
    width: 100% !important;
    text-align: center !important;
    background-color: none !important;
    border-radius: 0px !important;
    padding-left: 20px !important;
    margin-left: 0px;

    height: 100%;
}
.react-transform-component {
    height: 100% !important;
    width: 100% !important;
}
.react-transform-element {
    height: 100% !important;
    width: 100% !important;
}
.imagen-slide {
    max-width: 100% !important;
    position: absolute;
    height: calc(100vh);
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    opacity: 1;
    
}

.details-fab {
    text-transform: none !important;
}
.details-fab > .MuiFab-label {
    text-transform: none !important;
}
.slide-tools {
    position: absolute;
    z-index: 12;
    left: 0;
    right: 0;
    top: 40px;
}


.number-slide {
    font-size: 150%;
    max-width: 50px;
    padding: 0 !important;
    position: absolute;
    z-index: 12;
    margin-left: auto;
    top: 40px;
    background-color: rgba(0, 0, 0, 0.5) !important;
}


.studio-slide {
    font-size: 150%;
    max-width: 50px;

    padding: 4px !important;
    position: absolute;
    z-index: 12;
    margin-right: 10px;
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.number-slide:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
}


.watermark-logo-details{
    text-align: end !important;
    width: 100%;
    top:40px;
    margin-right:50px;
    position: absolute;
    padding-right: 10px;

}
.img-logo{
    max-width: 100%;
}

.titulo-obra{
    position: absolute;
    bottom:1%;
    height: 100;
    font-size: 250%;
    z-index: 12;
    
}

.pad-width{
    padding: 0 2rem;
}
.shrink-width{
    width: 50%;
    margin-left:25%;
}

.imagen-slider {
    max-width: 100% !important;
    max-height: 100% !important;
    justify-content: center; /* Center horizontally */
    display: block;
    margin: 0 auto; /* This centers the image horizontally */
}

.image-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  
  .image-content {
  position: relative;
}

.shadow-black-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10%;
    background: linear-gradient(to bottom,
    rgba(0, 0, 0, 0.0) 0%,
    rgba(0, 0, 0, 0.5) 25%,
    rgba(0, 0, 0, 0.7) 50%,
    rgba(0, 0, 0, 0.8) 75%,
    rgb(0, 0, 0,0.8) 100%
    );
  }

  .shadow-white-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10%;
    background: linear-gradient(
        to bottom,
            rgba(255, 255, 255, 0.0) 0%,
            rgba(255, 255, 255, 0.5) 25%,
            rgba(255, 255, 255, 0.7) 50%,
            rgba(255, 255, 255, 0.8) 75%,
            rgba(255, 255, 255, 0.9) 100%
            );
  }