.logoPrincipal {
    width: 145px;
    height: 40px;
}
.logoLogin {
    width: 180px;
    height: 50px;
}
.logoRedes {
    width: 20px;
    height: 20px;
}

.nombreToolBar {
    color: lightgray;
}

.linksToolbarDark {
    color: lightgray;
}
.linksToolbarDark:hover {
    color: gray;
    text-decoration: none !important;
}

.linksToolbarLight {
    color: rgb(31, 31, 31);
}
.linksToolbarLight:hover {
    color: black;
    text-decoration: none !important;
}
.botonColor {
    color: lightgray !important;
    background-color: gray;

    text-align: center;
}
